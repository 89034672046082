
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class BaseSelectedNote extends Vue {
	public closeBar(): void {
		this.$faroComponents.$emit('closebaseselectedmsg');
	}
}
