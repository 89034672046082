import Vue from 'vue';
import { $assert } from '@faroconnect/utils';
import { AuthzInterface, AuthzType } from '@faroconnect/authz-client';
import { Project } from '@faroconnect/authz-client/public/client/generated';
import { BaseEntity } from '@/classes/BaseEntity';
import { IBaseEntity, IWebShareProject } from '@/definitions/interfaces';
import { getDurationPretty } from '@/utils/date';
import { WorkspaceStats } from './WorkspaceStats';

// ----------------------------------------------------------------------------

export type MigrationState = 'pending' | 'running' | 'unpublished' | 'published' | /*UI-calculated*/ 'upgraded' | 'error' | 'aborting' | 'aborted';
type StepState = 'pending' | 'running' | 'complete' | 'error';
export type ProjectContentState = 'pending' | 'dataTransfer' | 'dataProcessing' | 'complete' | 'error';
export type AnyState = MigrationState | StepState | ProjectContentState;

export type UserSelection = 'all' | 'faro';

// ----------------------------------------------------------------------------

// https://faro01.atlassian.net/wiki/spaces/HOLO/pages/3445424625/Task+Type+Overview#Tasks-that-happen-during-a-WebShare-Project-Migration-to-Sphere-XG
// Some known task types used for the migration, but we should allow any string here.
export type TaskType = 'Img360Slicing' | 'ImgSheetStitching' | 'PointCloudLazToPotree' | 'DepthMapSlicing' | 'BimModelImport' | string;

export interface TaskStates {
	// Expected number of "Succeeded" results.
	Expected?: number;

	// Pending states:
	Created?: number;
	Scheduled?: number;
	Started?: number;

	// End states:
	Succeeded?: number;
	Aborted?: number;
	Failed?: number;
}

export interface TaskPerformanceMap {
	[taskType: string]: {
		TotalDurationInSeconds: number;
		Expected: number;
		Succeeded: number;
	};
}

/**
 * [PROG-44] ProgressAPI response element: State of the transformers of a migrated project, for one TaskType.
 */
export interface AggregatedTaskStateInfo {
	taskType: TaskType;
	taskStates: TaskStates;
	performance?: {
		totalDurationInSeconds: number;
	};
}

// ----------------------------------------------------------------------------

/**
 * Interface for the extended project object delivered by the Migrator's projects route.
 */
export interface ProjectExtended {
	Caption: string;
	AZ: Project;
	WS: IWebShareProject;
}

/**
 * Interface for step 1 of the Migration object to create a HoloBuilder company.
 */
export interface Step1I {
	Name: 'Create company';
	State: StepState;
	Error: string|null; // Error object converted to JSON string.
}

/**
 * Interface for step 2 of the Migration object to create the subscription in HoloBuilder.
 */
export interface Step2I {
	Name: 'Create subscription';
	State: StepState;
	ValidationErrors?: string | null;
	Error: string|null; // Error object converted to JSON string.
}

/**
 * Interface for step 3 of the Migration object to create the users in HoloBuilder.
 */
export interface Step3I {
	Name: 'Create users';
	State: StepState;
	Error: string|null; // Since we abort after the first failure, there's ever only 1 error.
	UserResults: { // Map of user UUIDs.
		[key: string]: {
			Name: string;
			Email?: string;
			Result: StepState;
		};
	};
}

/**
 * Interface for step 4 of the Migration object to create the company roles in HoloBuilder.
 */
export interface Step4I {
	Name: 'Create company roles';
	State: StepState;
	ValidationErrors?: string | null;
	Error: string|null; // Error object converted to JSON string.
}

/**
 * Interface for step 4b of the Migration object to create XG groups for user groups in HoloBuilder.
 */
export interface Step4bI {
	Name: 'Create XG groups for user groups';
	State: StepState;
	Error: string|null; // Error object converted to JSON string.
	GroupResults: { // Map of AuthZ group UUIDs.
		[key: string]: {
			Name: string;
			Result: StepState;
			HbGroup?: string; // Group UUID in HoloBuilder.
		};
	};
}

/**
 * Interface for step 4c of the Migration object to create XG groups for project collections in HoloBuilder.
 */
export interface Step4cI {
	Name: 'Create XG groups for project collections';
	State: StepState;
	Error: string|null; // Error object converted to JSON string.
	CollectionResults: { // Map of AuthZ collection UUIDs.
		[key: string]: {
			Name: string;
			Result: StepState;
			HbGroup?: string; // Group UUID in HoloBuilder.
		};
	};
}

/**
 * Interface for step 4d of the Migration object to assign members to XG groups created for user groups in HoloBuilder.
 */
export interface Step4dI {
	Name: 'Assign group members';
	State: StepState;
	Error: string|null; // Error object converted to JSON string.
	MemberResults: { // Map of AuthZ group UUIDs.
		[key: string]: {
			Name: string;
			Result: StepState;
			HbGroup?: string; // Group UUID in HoloBuilder.
		};
	};
}

/**
 * Interface for step 5 of the Migration object to create the HoloBuilder projects.
 */
export interface Step5I {
	Name: 'Create projects';
	State: StepState;
	Error: string|null; // Since we abort after the first failure, there's ever only 1 error.
	ProjectResults: { // Map of project UUIDs.
		[key: string]: {
			Name: string;
			Result: StepState;
			HbProject?: string; // Project UUID in HoloBuilder.
		};
	};
}

/**
 * Interface for step 6 of the Migration object to create the project roles in HoloBuilder.
 */
export interface Step6I {
	Name: 'Create project roles';
	State: StepState;
	Error: string|null; // Since we abort after the first failure, there's ever only 1 error.
	ProjectRoleResults: { // Map of project UUIDs.
		[key: string]: {
			Name: string;
			Result: StepState;
		};
	};
}

/**
 * Interface for step 7 of the Migration object to create the project content to HoloBuilder.
 */
export interface Step7I {
	Name: 'Create project content';
	State: StepState;
	ErrorTrigger: string|null; // Error object converted to JSON string. When triggering the project content migration failed.
	ErrorGet: string|null; // Error object converted to JSON string. When getting the project content migration status failed.
	ProjectContentResults: { // Map of project UUIDs.
		[key: string]: {
			Name: string;
			Result: ProjectContentState;
			Errors?: string | null; // JSON string: Map from TaskType to number of Aborted + Failed tasks, or null if no errors.
			StartTransferring?: string;
			StartProcessing?: string;
			EndProcessing?: string;
			Tasks?: AggregatedTaskStateInfo[];
		};
	};
}

/**
 * Interface for the steps of the Migration object.
 */
export interface StepsI {
	Step1: Step1I;
	Step2: Step2I;
	Step3: Step3I;
	Step4: Step4I;
	Step4b?: Step4bI;
	Step4c?: Step4cI;
	Step4d?: Step4dI;
	Step5: Step5I;
	Step6: Step6I;
	Step7: Step7I;
}

export interface DurationInfo {
	Standard: number;
	Min: number;
	Max: number;
	StandardStr: string;
	StandardShortStr: string;
	MinStr: string;
	MaxStr: string;
}

/**
 * @returns Dummy "Steps" attribute value, used when reading all migrations.
 */
function getDummySteps(): StepsI {
	return {
		Step1: { State: 'pending' },
		Step2: { State: 'pending' },
		Step3: { State: 'pending' },
		Step4: { State: 'pending' },
		Step4b: { State: 'pending' },
		Step4c: { State: 'pending' },
		Step4d: { State: 'pending' },
		Step5: { State: 'pending' },
		Step6: { State: 'pending' },
		Step7: { State: 'pending' },
	} as StepsI;
}

export interface IMigration extends IBaseEntity {
	Class: string;
	/**
	 * Workspace UUID.
	 */
	Workspace: string;
	/**
	 * Project UUIDs to migrate if only a partial migration is desired.
	 * For a full migration, an empty array is given.
	 */
	Projects: string[] | null;
	/**
	 * Job ID (integer).
	 */
	Job: number | null;
	/**
	 * UUID of user who requested the migration.
	 */
	Creator: string;
	/**
	 * Email of user who requested the migration (if known). Only set when reading a single migration.
	 */
	CreatorEmail: string | undefined;
	/**
	 * UUID of the company in HoloBuilder.
	 */
	HbCompany: string;
	/**
	 * Name of the company in HoloBuilder.
	 */
	HbCompanyName: string | null;
	/**
	 * Email address of the user with the Enterprise Admin role in HoloBuilder.
	 */
	HbEnterpriseAdmin: string | null;
	/**
	 * URL of the company dashboard in HoloBuilder.
	 */
	HbCompanyDashboardUrl: string | null;
	/**
	 * State of the migration.
	 */
	State: MigrationState;
	/**
	 * Information about the migration steps.
	 * [omitted in read-all API route, since too large; replaced with dummy object]
	 */
	Steps: StepsI;
	/**
	 * Schedule date for the migration to start.
	 */
	ScheduledDate: string | null;
	/**
	 * Date when the worker takes the migration job from the queue to process it.
	 */
	StartDate: string | null;
	/**
	 * Date when the worker takes the migration job from the queue to retry it.
	 */
	RetryDate: string | null;
	/**
	 * Date when the worker is finished with all the work.
	 */
	FinishDate: string | null;
	/**
	 * Date when the migration was deleted.
	 */
	DeleteDate: string | null;
	/**
	 * Heartbeat gets updated regularly to Now() while the worker process is running, to detect stale migrations.
	 * "worker-gc.ts" will set the state to "error" and fail the job, if the heartbeat is too old.
	 */
	Heartbeat: string | Date | null;
	/**
	 * QA users which are given the Enterprise Viewer role for the migrated workspace while it's unpublished.
	 * [omitted in read-all API route, since too large]
	 */
	QAUsers?: string[] | null;
	/**
	 * User selection: All users, or only the owner and FARO users.
	 */
	UserSelection: UserSelection | null;
	/**
	 * True if the workspace redirects to the XG company which was created in this migration.
	 * Can be true only for max. one migration for each workspace.
	 */
	XgRedirect: boolean | null;
	/**
	 * If set to true, the migration will be auto-published after successful completion.
	 * This will publish the workspace on XG and set the XgRedirect flag to true.
	 */
	AutoPublish: boolean | null;
	/**
	 * Flag if the success email has been sent after the redirect has been activated for a successful migration.
	 */
	EmailSent: boolean | null;
	/**
	 * Email addresses of the recipients of the success email.
	 */
	EmailRecipients: string[] | null;
	/**
	 * Number of files which were transferred from S3 to Azure.
	 */
	TransferredFilesCount: number | null;
	/**
	 * Total size of files which were transferred from S3 to Azure, in bytes.
	 */
	TransferredFilesSize: number | null;
	/**
	 * Email recipients that were bounced given by the send grid API.
	 */
	EmailRecipientsBounced: string[] | null;
	/**
	 * Info if the workspace S3 files were already deleted from WebShare. (DataHub bucket will be deleted all at once at the end.)
	 */
	DataDeleted: string | Date | null;
}

export interface MigrationStubI {
	UUID: string;
	/**
	 * Workspace UUID.
	 */
	Workspace: string;
	/**
	 * Date when the worker takes the migration job from the queue to process it.
	 * Since the route action only returns migrations with a finish date within the provided date range, the start date
	 * is always set.
	 */
	Start: string;
	/**
	 * Date when the worker is finished with all the work.
	 * Since the route action only returns migrations with a finish date within the provided date range, the finish date
	 * is always set.
	 */
	End: string;
	/**
	 * Date when the worker is finished with all the work and everything was an immediate success.
	 */
	Suc: string | null;
	/**
	 * State of the migration.
	 * Since we don't consider the XgRedirect attribute here yet, we have 'published' instead of 'upgraded'.
	 */
	State: Exclude<MigrationState, 'upgraded'>;
	/**
	 * Project migration results.
	 */
	Results: { // Map of project UUIDs.
		[key: string]: {
			Result: ProjectContentState,
			Errors?: string | null; // JSON string: Map from TaskType to number of Aborted + Failed tasks, or null if no errors.
			TransDur: number; // Duration of data transfer in milliseconds.
			ProcDur: number; // Duration of data processing in milliseconds.
			TaskPerf?: TaskPerformanceMap; // Aggregated durations of each task type in seconds.
		},
	},
	/**
	 * Total size of files which were transferred from S3 to Azure, in bytes.
	 */
	TransferredFilesCount: number | null;
	/**
	 * Total size of files which were transferred from S3 to Azure, in bytes.
	 */
	TransferredFilesSize: number | null;
	/**
	 * Size of the original WebShare projects in bytes.
	 * Not provided by Migrator backend, but set in MigrationStatsPage.vue.
	 */
	SizeWebShare?: number;
	/**
	 * True if we know the size of all WebShare projects.
	 */
	SizeWebShareAllKnown?: boolean;
}

export interface ProjectResultI {
	UUID: string;
	Name: string;
	Result: StepState;
}

export interface GroupResultI {
	UUID: string;
	Name: string;
	Result: StepState;
}

export interface CollectionResultI {
	UUID: string;
	Name: string;
	Result: StepState;
}

export interface MemberResultI {
	UUID: string;
	Name: string;
	Result: StepState;
}

export interface UserResultI {
	UUID: string;
	Name: string;
	Result: StepState;
	IsQA: boolean;
}

export interface ProjectRoleResultI {
	UUID: string;
	Name: string;
	Result: StepState;
}

export interface ProjectContentResultI {
	UUID: string;
	Name: string;
	Result: ProjectContentState;
	Errors?: string | null;
	Tasks?: AggregatedTaskStateInfo[];
}

/**
 * @param result Project content result.
 * @returns Sort order, with "most relevant" as smallest value.
 */
function projectContentStateOrder(result: ProjectContentResultI): number {
	const projectResult = result.Result;

	let runningWithFailure = false;
	if (projectResult === 'dataProcessing') {
		const tasks = result.Tasks || [];
		for (const task of tasks) {
			if ((task.taskStates.Succeeded || 0) >= (task.taskStates.Expected || 0)) {
				continue; // Ignore failures if the task was replaced with a new one, and the new one succeeded.
			}
			const abortedFailed = (task.taskStates.Aborted || 0) + (task.taskStates.Failed || 0);
			if (abortedFailed > 0) {
				runningWithFailure = true;
				break;
			}
		}
	}

	if (runningWithFailure) {
		return 1; // 2nd postion
	} else {
		switch (projectResult) {
			case 'error': return 0; // top
			case 'dataProcessing': return 2; // 3rd position
			case 'dataTransfer': return 3;
			case 'pending': return 4;
			case 'complete': return 5; // bottom
			default: return -1; // unexpected
		}
	}
}

// ----------------------------------------------------------------------------

/**
 * Migration class from the Sphere Migrator service.
 */
export class Migration extends BaseEntity implements IMigration {
	public static readonly constructorName = Migration.name;
	/**
	 * Not all project data gets migrated, e.g. not the scan overlays. Heuristic value provided by TT in
	 * https://faro01.atlassian.net/wiki/spaces/FC/pages/3535209417/2023-05-09+Migration+Data+Scope
	 */
	public static SIZE_FACTOR: number = 0.7;

	/**
	 * Since this date, we automatically send success emails in PROD when enabling the redirect.
	 * Date is in ISO format, same as Migration.CreationDate.
	 */
	public static SUCCESS_EMAILS_SENT_SINCE = '2024-07-19T00:00:00.000Z';

	public static fromResponse(json: IMigration) {
		return new Migration(json);
	}

	public static forRequest(json: IMigration) {
		$assert.Assert(false, 'Why is the Landing Page trying to create/update a Migration object?');
		return new Migration(json);
	}

	/**
	 * Gets the duration for project transfer and processing in milliseconds.
	 * If `end` is undefined, but the migration is in progress, the current timestamp is used instead.
	 * 0 is returned if no duration is available.
	 * @author OK
	 */
	public static getDurationMs(start?: string, end?: string, inProgress?: boolean): number {
		if (start && (end || inProgress)) {
			const endTimestamp = end ? (new Date(end)).valueOf() : Date.now();
			const durationMs = endTimestamp - (new Date(start)).valueOf();
			$assert.Assert(durationMs >= 0, 'getDuration: Expected: end >= start. Got negative durationMs: ' + durationMs);
			return durationMs;
		} else {
			return 0;
		}
	}

	/**
	 * Gets the duration for project transfer and processing in "hh:mm:ss" or "D days, hh:mm:ss" format.
	 * If `end` is undefined, but the migration is in progress, the current timestamp is used instead.
	 * "" is returned if no duration is available.
	 * @author OK
	 */
	public static getDuration(start?: string, end?: string, inProgress?: boolean): string {
		if (start && (end || inProgress)) {
			const durationMs = this.getDurationMs(start, end, inProgress);
			const days = Math.floor(durationMs / 86400000);
			const daysStr = (days > 0) ? (days > 1 ? `${days} days, ` : `${days} day, `) : '';
			const timeStr = new Date(durationMs).toISOString().substring(11, 19);
			return daysStr + timeStr + (end ? '' : '...');
		} else {
			return '';
		}
	}

	/**
	 * Calculates the estimated duration for the migration of the workspace, under the assumption that all projects
	 * were selected for migration.
	 * @author OK
	 */
	public static async calcEstimatedDurations(region: AuthzType.WebshareRegion, uuidWorkspace: string): Promise<DurationInfo> {
		const statsObj = await Vue.prototype.$tsStore.migrations.getWorkspaceStats({ region, uuidWorkspace }) as WorkspaceStats;

		let gbPerMin = 0;

		// These values were determined by looking at instantly successful migrations between 1 June 2024 and
		// 4 July 2024 for the available workspace size ranges.
		// Linear interpolation between these values:
		// 	  0 GB: 0.05 GB/min
		//   10 GB: 0.1 GB/min
		//  100 GB: 0.3 GB/min
		// 1000 GB: 0.6 GB/min
		const size = statsObj.StatsAggregated.Size;
		if (size < 10) {
			gbPerMin = 0.005 * size + 0.05;
		} else if (size < 100) {
			gbPerMin = 0.002222222 * size + 0.07777778;
		} else if (size < 1000) {
			gbPerMin = 0.0003333333 * size + 0.2666667;
		} else {
			gbPerMin = 0.6;
		}

		// Workspaces with few point clouds take shorter. Workspaces with many point clouds take longer.
		// Around 33.3% is a realistic maximum for the size of all point clouds compared to the total size.
		const pcsSize = statsObj.StatsAggregated.PCsSize ?? 0;
		const pcSSizePercent = 100 * pcsSize / size;
		let pcFactor = 0;
		// Linear interpolation between these values for the point cloud size factor:
		//  0%:  0.25
		// 10%:  0
		// 33%: -0.25
		if (pcSSizePercent < 10) {
			pcFactor = -0.025 * pcSSizePercent + 0.25;
		} else if (pcSSizePercent < 100 / 3) {
			pcFactor = -0.01071429 * pcSSizePercent + 0.1071429;
		} else {
			pcFactor = -0.25;
		}

		gbPerMin = gbPerMin + pcFactor * gbPerMin;

		// Set a minimum standard duration of 5 minutes for extremely small workspaces.
		let durationSec = Math.round(Math.max(300, 60 * size / gbPerMin));

		// However, single large point clouds dominate the total duration time. For those, Manuel Caputo said it takes
		// roughly 48 hours for a 100 GB CPE file. So let's take 30 minutes per GB.
		let largestPCSizeInGB = 0;
		for (const projectId in statsObj.StatsProjects) {
			if (!statsObj.StatsProjects[projectId].PCObjects) {
				continue;
			}
			for (const pcId in statsObj.StatsProjects[projectId].PCObjects) {
				largestPCSizeInGB = Math.max(largestPCSizeInGB, statsObj.StatsProjects[projectId].PCObjects?.[pcId].Size ?? 0);
			}
		}
		const durationPCSec = Math.round(largestPCSizeInGB * 1800);
		durationSec = Math.max(durationSec, durationPCSec);

		// For small workspaces, the uncertainty is even higher than for large ones.
		let minFactor = 1;
		let maxFactor = 1;
		if (size < 10) {
			minFactor = 0.6;
			maxFactor = 1.5;
		} else if (size < 100) {
			minFactor = 0.65;
			maxFactor = 1.45;
		} else if (size < 1000) {
			minFactor = 0.7;
			maxFactor = 1.4;
		} else {
			minFactor = 0.75;
			maxFactor = 1.35;
		}

		const minSec = Math.round(durationSec * minFactor);
		const maxSec = Math.round(durationSec * maxFactor);

		return {
			Standard: durationSec,
			Min: minSec,
			Max: maxSec,
			StandardStr: getDurationPretty(durationSec),
			StandardShortStr: getDurationPretty(durationSec, /*short*/ true),
			MinStr: getDurationPretty(minSec),
			MaxStr: getDurationPretty(maxSec),
		};
	}

	public Class: string = 'Migration';
	/**
	 * Workspace UUID.
	 */
	public Workspace: string;
	/**
	 * Project UUIDs to migrate if only a partial migration is desired.
	 * For a full migration, an empty array is given.
	 */
	public Projects: string[] | null;
	/**
	 * Job ID (integer).
	 */
	public Job: number | null;
	/**
	 * UUID of user who requested the migration.
	 */
	public Creator: string;
	/**
	 * Email of user who requested the migration (if known). Only set when reading a single migration.
	 */
	public CreatorEmail: string | undefined;
	/**
	 * UUID of the company in HoloBuilder.
	 */
	public HbCompany: string;
	/**
	 * Name of the company in HoloBuilder.
	 */
	public HbCompanyName: string | null;
	/**
	 * Email address of the user with the Enterprise Admin role in HoloBuilder.
	 */
	public HbEnterpriseAdmin: string | null;
	/**
	 * URL of the company dashboard in HoloBuilder.
	 */
	public HbCompanyDashboardUrl: string | null;
	/**
	 * State of the migration.
	 */
	public State: MigrationState;
	/**
	 * Information about the migration steps.
	 * [omitted in read-all API route, since too large; replaced with dummy object]
	 */
	public Steps: StepsI;
	/**
	 * Schedule date for the migration to start.
	 */
	public ScheduledDate: string | null;
	/**
	 * Date when the worker takes the migration job from the queue to process it.
	 */
	public StartDate: string | null;
	/**
	 * Date when the worker takes the migration job from the queue to retry it.
	 */
	public RetryDate: string | null;
	/**
	 * Date when the worker is finished with all the work.
	 */
	public FinishDate: string | null;
	/**
	 * Date when the migration was deleted.
	 */
	public DeleteDate: string | null;
	/**
	 * Heartbeat gets updated regularly to Now() while the worker process is running, to detect stale migrations.
	 * "worker-gc.ts" will set the state to "error" and fail the job, if the heartbeat is too old.
	 */
	public Heartbeat: string | Date | null;
	/**
	 * QA users which are given the Enterprise Viewer role for the migrated workspace while it's unpublished.
	 * [omitted in read-all API route, since too large]
	 */
	public QAUsers?: string[] | null;
	/**
	 * User selection: All users, or only the owner and FARO users.
	 */
	public UserSelection: UserSelection | null;
	/**
	 * True if the workspace redirects to the XG company which was created in this migration.
	 * Can be true only for max. one migration for each workspace.
	 */
	public XgRedirect: boolean;
	/**
	 * If set to true, the migration will be auto-published after successful completion.
	 * This will publish the workspace on XG and set the XgRedirect flag to true.
	 */
	public AutoPublish: boolean | null;
	/**
	 * Flag if the success email has been sent after the redirect has been activated for a successful migration.
	 */
	public EmailSent: boolean | null;
	/**
	 * Email addresses of the recipients of the success email.
	 */
	public EmailRecipients: string[] | null;
	/**
	 * Email recipients that were bounced given by the send grid API.
	 */
	public EmailRecipientsBounced: string[] | null;
	/**
	 * Info if the workspace S3 files were already deleted from WebShare. (DataHub bucket will be deleted all at once at the end.)
	 */
	public DataDeleted: string | Date | null;
	/**
	 * Number of files which were transferred from S3 to Azure.
	 */
	public TransferredFilesCount: number | null;
	/**
	 * Total size of files which were transferred from S3 to Azure, in bytes.
	 */
	public TransferredFilesSize: number | null;

	/**
	 * The project results as an array sorted by (call order = project name) as provided by the Migrator.
	 */
	public projectResults: ProjectResultI[];
	/**
	 * The group results as an array sorted by (call order = group name) as provided by the Migrator.
	 */
	public groupResults: GroupResultI[];
	/**
	 * The collection results as an array sorted by (call order = collection name) as provided by the Migrator.
	 */
	public collectionResults: CollectionResultI[];
	/**
	 * The group assignment results as an array sorted by (call order = group name) as provided by the Migrator.
	 */
	public memberResults: MemberResultI[];
	/**
	 * The user results as an array sorted by (call order = user name) as provided by the Migrator.
	 */
	public userResults: UserResultI[];
	/**
	 * The project role results as an array sorted by (call order = project UUID) as provided by the Migrator.
	 */
	public projectRoleResults: ProjectRoleResultI[];
	/**
	 * The project content results as an array sorted by (call order = project name) as provided by the Migrator.
	 */
	public projectContentResults: ProjectContentResultI[];
	/**
	 * The number of projects whose project content shall be migrated.
	 */
	public numContentAll: number | null;
	/**
	 * The number of projects whose project content was migrated correctly.
	 */
	public numContentComplete: number | null;
	/**
	 * The number of completed projects whose project content failed to be migrated correctly.
	 */
	public numContentError: number | null;
	/**
	 * The number of running projects with task failures.
	 */
	public numRunningError: number | null;
	/**
	 * Store grouping and counting each error, e.g. {"ProjectAPI": 2, "PointCloudLazToPotree": 21}
	 */
	public aggContentErrors: { [key: string]: number };
	/**
	 * Link to new dashboard: https://sphere[.ENV].holobuilder.(com|eu)/WORKSPACE_UUID
	 * ...instead of          https://dashboard[.ENV].holobuilder.(com|eu)/WORKSPACE_UUID
	 */
	public xgDashboardUrl: string | null;
	public xgViewerUrl: string | null;
	/**
	 * Map from project UUID to page in new dashboard: https://sphere[.ENV].holobuilder.(com|eu)/WORKSPACE_UUID/projects/PROJECT_UUID/details
	 */
	public xgDashboardUrlForProject: { [projectUuid: string]: string };
	public xgViewerUrlForProject: { [projectUuid: string]: string };

	/**
	 * If the migration has started already: StartDate.
	 * Else, if the migration was scheduled: ScheduledDate.
	 * Else: CreationDate.
	 */
	public startOrScheduledOrCreationDate: string;

	// Inherited but unused
	public ImgUrl: string = '';
	public DefaultImgUrl: string = '';

	protected constructor(obj: IMigration) {
		super(obj);
		this.Workspace = obj.Workspace;
		this.Projects = obj.Projects;
		this.Job = obj.Job;
		this.Creator = obj.Creator;
		this.CreatorEmail = obj.CreatorEmail;
		this.HbCompany = obj.HbCompany;
		this.HbCompanyName = obj.HbCompanyName;
		this.HbCompanyDashboardUrl = obj.HbCompanyDashboardUrl;
		this.HbEnterpriseAdmin = obj.HbEnterpriseAdmin;
		this.State = (obj.State === 'published' && obj.XgRedirect) ? 'upgraded' : obj.State;
		this.Steps = obj.Steps || getDummySteps();
		this.ScheduledDate = obj.ScheduledDate;
		this.StartDate = obj.StartDate;
		this.RetryDate = obj.RetryDate;
		this.DeleteDate = obj.DeleteDate;
		this.FinishDate = obj.FinishDate;
		this.startOrScheduledOrCreationDate = obj.StartDate && !obj.StartDate.startsWith('1970') ? obj.StartDate : (
			obj.ScheduledDate && !obj.ScheduledDate.startsWith('1970') ? obj.ScheduledDate : obj.CreationDate
		);
		this.Heartbeat = obj.Heartbeat;
		this.QAUsers = obj.QAUsers;
		this.UserSelection = obj.UserSelection || null;
		if (this.UserSelection === 'all') {
			// Make it easier to detect unusual options.
			this.UserSelection = null;
		}
		this.XgRedirect = obj.XgRedirect || false;
		this.AutoPublish = obj.AutoPublish || false;
		this.EmailSent = obj.EmailSent || false;
		this.EmailRecipients = obj.EmailRecipients || [];
		this.EmailRecipientsBounced = obj.EmailRecipientsBounced || [];
		this.DataDeleted = obj.DataDeleted || null;
		this.TransferredFilesCount = obj.TransferredFilesCount ?? null;
		this.TransferredFilesSize = obj.TransferredFilesSize ?? null;

		this.xgDashboardUrl = obj.HbCompanyDashboardUrl?.replace('https://dashboard.', 'https://sphere.') ?? null;
		this.xgViewerUrl = obj.HbCompanyDashboardUrl?.replace('https://dashboard.', 'https://viewer.') ?? null;
		this.xgViewerUrl = this.xgViewerUrl?.substring(0, this.xgViewerUrl.lastIndexOf('/')) ?? null; // remove workspace UUID
		this.xgDashboardUrlForProject = {};
		this.xgViewerUrlForProject = {};
		this.aggContentErrors = {};

		this.userResults = [];
		for (const userUuid in (this.Steps?.Step3?.UserResults || [])) {
			this.userResults.push({
				UUID: userUuid,
				Name: this.Steps.Step3.UserResults[userUuid].Name,
				Result: this.Steps.Step3.UserResults[userUuid].Result,
				IsQA: this.QAUsers?.includes(this.Steps.Step3.UserResults[userUuid].Email || '') || false,
			});
		}
		this.userResults.sort((a, b) => a.Name.toLowerCase().localeCompare(b.Name.toLowerCase()));

		this.groupResults = [];
		if (this.Steps?.Step4b) {
			for (const groupUuid in (this.Steps?.Step4b.GroupResults || [])) {
				this.groupResults.push({
					UUID: groupUuid,
					Name: this.Steps.Step4b.GroupResults[groupUuid].Name,
					Result: this.Steps.Step4b.GroupResults[groupUuid].Result,
				});
			}
			this.groupResults.sort((a, b) => a.Name.toLowerCase().localeCompare(b.Name.toLowerCase()));
		}

		this.collectionResults = [];
		if (this.Steps?.Step4c) {
			for (const groupUuid in (this.Steps?.Step4c.CollectionResults || [])) {
				this.collectionResults.push({
					UUID: groupUuid,
					Name: this.Steps.Step4c.CollectionResults[groupUuid].Name,
					Result: this.Steps.Step4c.CollectionResults[groupUuid].Result,
				});
			}
			this.collectionResults.sort((a, b) => a.Name.toLowerCase().localeCompare(b.Name.toLowerCase()));
		}

		this.memberResults = [];
		if (this.Steps?.Step4d) {
			for (const groupUuid in (this.Steps?.Step4d.MemberResults || [])) {
				this.memberResults.push({
					UUID: groupUuid,
					Name: this.Steps.Step4d.MemberResults[groupUuid].Name,
					Result: this.Steps.Step4d.MemberResults[groupUuid].Result,
				});
			}
			this.memberResults.sort((a, b) => a.Name.toLowerCase().localeCompare(b.Name.toLowerCase()));
		}

		this.projectResults = [];
		for (const projectUuid in (this.Steps?.Step5?.ProjectResults || [])) {
			this.projectResults.push({
				UUID: projectUuid,
				Name: this.Steps.Step5.ProjectResults[projectUuid].Name,
				Result: this.Steps.Step5.ProjectResults[projectUuid].Result,
			});
			const hbProjectUuid = this.Steps.Step5.ProjectResults[projectUuid].HbProject;
			if (hbProjectUuid) {
				this.xgDashboardUrlForProject[projectUuid] = this.xgDashboardUrl + '/projects/' + hbProjectUuid + '/details';
				this.xgViewerUrlForProject[projectUuid] = this.xgViewerUrl + '/project/' + hbProjectUuid;
			}
		}
		this.projectResults.sort((a, b) => a.Name.toLowerCase().localeCompare(b.Name.toLowerCase()));

		this.projectRoleResults = [];
		for (const projectUuid in (this.Steps.Step6.ProjectRoleResults || [])) {
			this.projectRoleResults.push({
				UUID: projectUuid,
				Name: this.Steps.Step6.ProjectRoleResults[projectUuid].Name,
				Result: this.Steps.Step6.ProjectRoleResults[projectUuid].Result,
			});
		}
		this.projectRoleResults.sort((a, b) => a.UUID.toLowerCase().localeCompare(b.UUID.toLowerCase()));

		this.numContentAll = 0;
		this.numContentComplete = 0;
		this.numContentError = 0;
		this.numRunningError = 0;

		this.projectContentResults = [];
		for (const projectUuid in this.Steps.Step7.ProjectContentResults) {
			const projectResult = this.Steps.Step7.ProjectContentResults[projectUuid].Result;
			this.projectContentResults.push({
				UUID: projectUuid,
				Name: this.Steps.Step7.ProjectContentResults[projectUuid].Name,
				Result: projectResult,
				Errors: this.Steps.Step7.ProjectContentResults[projectUuid].Errors,
				Tasks: this.Steps.Step7.ProjectContentResults[projectUuid].Tasks,
			});

			this.numContentAll++;
			if (projectResult === 'complete') {
				this.numContentComplete++;
			} else if (projectResult === 'error') {
				this.numContentError++;
			}

			// Add task errors to the aggregated error map.
			if (projectResult === 'error' || projectResult === 'dataProcessing') {
				let foundAny = false;
				const tasks = this.Steps.Step7.ProjectContentResults[projectUuid].Tasks || [];
				for (const task of tasks) {
					if ((task.taskStates.Succeeded || 0) >= (task.taskStates.Expected || 0)) {
						continue; // Ignore failures if the task was replaced with a new one, and the new one succeeded.
					}
					const abortedFailed = (task.taskStates.Aborted || 0) + (task.taskStates.Failed || 0);
					if (abortedFailed > 0) {
						this.aggContentErrors[task.taskType] = (this.aggContentErrors[task.taskType] || 0) + abortedFailed;
						foundAny = true;
					}
				}
				if (projectResult === 'dataProcessing' && foundAny) {
					this.numRunningError++;
				}
				if (!foundAny && projectResult === 'error') {
					try {
						const pErrors = JSON.parse(this.Steps.Step7.ProjectContentResults[projectUuid].Errors as string);
						for (const errType in pErrors) {
							const nFailed = Array.isArray(pErrors[errType]) ? pErrors[errType][0] : pErrors[errType];
							if (typeof nFailed === 'number') {
								this.aggContentErrors[errType] = (this.aggContentErrors[errType] || 0) + nFailed;
							} else {
								// "ProjectAPI": "File copy or model tree creation"
								this.aggContentErrors[errType] = (this.aggContentErrors[errType] || 0) + 1;
							}
						}
					} catch (e) {
						console.error(e);
						this.aggContentErrors['Unknown'] = (this.aggContentErrors['Unknown'] || 0) + 1;
					}
				}
			}
		}
		// Sort: Most relevant on top (error).
		this.projectContentResults.sort((a, b) => {
			const aResultOrder = projectContentStateOrder(a);
			const bResultOrder = projectContentStateOrder(b);
			if (aResultOrder !== bResultOrder) {
				return aResultOrder - bResultOrder;
			} else {
				return a.Name.toLowerCase().localeCompare(b.Name.toLowerCase());
			}
		});
	}

	public get workspaceObj(): AuthzInterface.IWorkspace | null {
		return Vue.prototype.$tsStore.workspaces.ItemsMap[this.Workspace] ?? null;
	}

	public get workspaceName(): string {
		return this.workspaceObj?.Name ?? '';
	}

	private prettyDateTime(date: string | null): string {
		return date ? date.substring(0, 10) + ' – ' + date.substring(11, 19) : 'n/a';
	}

	public get prettyCreationDateTime(): string {
		return this.prettyDateTime(this.CreationDate);
	}

	public get prettyStartDateTime(): string {
		return this.prettyDateTime(this.StartDate);
	}

	public get prettyStartDateTimeNoDash(): string {
		return this.prettyDateTime(this.StartDate);
	}

	public get prettyRetryDateTime(): string {
		return this.prettyDateTime(this.RetryDate);
	}

	public get prettyFinishDateTime(): string {
		return this.prettyDateTime(this.FinishDate);
	}

	public get prettyDeleteDateTime(): string {
		return this.prettyDateTime(this.DeleteDate);
	}

	/**
	 * Returns the CSS class to use for the provided state.
	 * @author OK
	 */
	public getCss(state: AnyState): string {
		if (state === 'pending') {
			return 'pending';
		} else if (state === 'running' || state === 'dataTransfer' || state === 'dataProcessing') {
			return 'running';
		} else if (state === 'aborted' || state === 'aborting') {
			return 'aborted';
		} else if (state === 'error') {
			return 'error2';
		} else if (state === 'unpublished') {
			return 'unpublished';
		} else if (state === 'complete' || state === 'published') {
			return 'complete';
		} else if (state === 'upgraded') {
			return 'upgraded';
		} else {
			return 'error2';
		}
	}

	/**
	 * Returns the icon to use for the provided state, to be imported as an <img/>.
	 * @author OK, BE, MF
	 */
	public getIcon(state: AnyState): string {
		switch (state) {
			case 'pending':
				// return '$vuetify.icons.36_active_generic-clock';
				return '/home/img/generic-clock_l.732afbed.svg';
			case 'running':
			case 'dataTransfer':
			case 'dataProcessing':
				// return '$vuetify.icons.36_active_service-queue-running';
				return '/home/img/service-queue-running_l.b7ff5f41.svg';
			case 'aborting':
			case 'aborted':
				// return '$vuetify.icons.36_error_action-stop';
				return '/home/img/action-stop_l.af167072.svg';
			case 'error':
				// return '$vuetify.icons.36_error_action-warning';
				return '/home/img/action-warning_l.7711e090.svg';
			case 'unpublished':
				// return '$vuetify.icons.36_warning_action-shield';
				return '/home/img/action-shield_l.a53a530a.svg';
			case 'complete':
			case 'published':
				// return '$vuetify.icons.36_success_action-success';
				return '/home/img/action-success_l.f82f6dac.svg';
			case 'upgraded':
				// return '$vuetify.icons.36_success_generic-asterisk';
				return '/home/img/generic-asterisk_l.6cc7bca0.svg';
			default:
				// return '$vuetify.icons.36_error_action-help';
				return '/home/img/action-help_l.0250bb91.svg';
		}
	}

	/**
	 * Returns the info icon to use for the provided state.
	 * @author OK
	 */
	public getInfoIcon(state: AnyState): string {
		switch (state) {
			case 'pending':
			case 'running':
			case 'dataTransfer':
			case 'dataProcessing':
				return '$vuetify.icons.36_active_generic-info';
			case 'aborting':
			case 'aborted':
			case 'error':
				return '$vuetify.icons.36_error_generic-info';
			case 'unpublished':
				return '$vuetify.icons.36_warning_generic-info';
			case 'complete':
			case 'published':
			case 'upgraded':
				return '$vuetify.icons.36_success_generic-info';
			default:
				return '$vuetify.icons.36_generic-info';
		}
	}

	/**
	 * Returns the caption to use for the provided state.
	 * @author OK, BE
	 */
	public getCaption(state?: AnyState): string {
		if (arguments.length === 0) {
			state = this.State;
		}

		switch (state) {
			case 'pending':
				return 'Pending';
			case 'running':
				return 'Running';
			case 'dataTransfer':
				return 'Transferring project data';
			case 'dataProcessing':
				return 'Processing project data';
			case 'aborting':
				return 'Aborting';
			case 'aborted':
				return 'Aborted';
			case 'error':
				return 'Failed';
			case 'complete':
				return 'Migrated';
			case 'unpublished':
				return 'Unpublished';
			case 'published':
				return 'Published';
			case 'upgraded':
				return 'Upgraded';
			default:
				return `${state}`;
		}
	}

	/**
	 * Gets the project transfer duration in hh:mm:ss format. "" is returned if no duration is available.
	 * @author OK
	 */
	public getTransferDuration(projectUuid: string): string {
		const start = this.Steps.Step7.ProjectContentResults[projectUuid]?.StartTransferring;
		const end = this.Steps.Step7.ProjectContentResults[projectUuid]?.StartProcessing;
		// If a migration is killed, the step states currently remain unchanged. -> Checking `this.State` as well.
		const inProgress = this.State === 'running' && this.Steps.Step7.ProjectContentResults[projectUuid]?.Result === 'dataTransfer';
		return Migration.getDuration(start, end, inProgress);
	}

	/**
	 * Gets the project processing duration in hh:mm:ss format. "" is returned if no duration is available.
	 * @author OK
	 */
	public getProcessingDuration(projectUuid: string): string {
		const start = this.Steps.Step7.ProjectContentResults[projectUuid]?.StartProcessing;
		const end = this.Steps.Step7.ProjectContentResults[projectUuid]?.EndProcessing;
		// If a migration is killed, the step states currently remain unchanged. -> Checking `this.State` as well.
		const inProgress = this.State === 'running' && this.Steps.Step7.ProjectContentResults[projectUuid]?.Result === 'dataProcessing';
		return Migration.getDuration(start, end, inProgress);
	}

	/** @returns Duration from creation to start in hh:mm:ss format, or "". */
	public getQueuedDuration(): string {
		const inProgress = this.State === 'pending';
		return Migration.getDuration(this.CreationDate || undefined, this.StartDate || undefined, inProgress);
	}
	/** @returns Duration from start to finish in hh:mm:ss format, or "". */
	public getMigrationDuration(): string {
		const inProgress = this.State === 'running';
		return Migration.getDuration(this.StartDate || undefined, this.FinishDate || undefined, inProgress);
	}

	public getTasks(projectUuid: string): AggregatedTaskStateInfo[] {
		return this.Steps.Step7.ProjectContentResults[projectUuid]?.Tasks || [];
	}

	public hasError1(): boolean {
		return !!this.Steps.Step1.Error;
	}
	public hasError2(): boolean {
		return !!this.Steps.Step2.Error;
	}
	public hasError3(): boolean {
		return !!this.Steps.Step3.Error;
	}
	public hasError4(): boolean {
		return !!this.Steps.Step4.Error;
	}
	public hasError4b(): boolean {
		return !!this.Steps.Step4b?.Error;
	}
	public hasError4c(): boolean {
		return !!this.Steps.Step4c?.Error;
	}
	public hasError4d(): boolean {
		return !!this.Steps.Step4d?.Error;
	}
	public hasError5(): boolean {
		return !!this.Steps.Step5.Error;
	}
	public hasError6(): boolean {
		return !!this.Steps.Step6.Error;
	}
	public hasErrorTrigger(): boolean {
		return !!this.Steps.Step7.ErrorTrigger;
	}
	public hasErrorGet(): boolean {
		return !!this.Steps.Step7.ErrorGet;
	}

	public getError1(): any {
		return this.Steps.Step1.Error ? JSON.parse(this.Steps.Step1.Error) : null;
	}
	public getError2(): any {
		return this.Steps.Step2.Error ? JSON.parse(this.Steps.Step2.Error) : null;
	}
	public getError3(): any {
		return this.Steps.Step3.Error ? JSON.parse(this.Steps.Step3.Error) : null;
	}
	public getError4(): any {
		return this.Steps.Step4.Error ? JSON.parse(this.Steps.Step4.Error) : null;
	}
	public getError4b(): any {
		return this.Steps.Step4b?.Error ? JSON.parse(this.Steps.Step4b.Error) : null;
	}
	public getError4c(): any {
		return this.Steps.Step4c?.Error ? JSON.parse(this.Steps.Step4c.Error) : null;
	}
	public getError4d(): any {
		return this.Steps.Step4d?.Error ? JSON.parse(this.Steps.Step4d.Error) : null;
	}
	public getError5(): any {
		return this.Steps.Step5.Error ? JSON.parse(this.Steps.Step5.Error) : null;
	}
	public getError6(): any {
		return this.Steps.Step6.Error ? JSON.parse(this.Steps.Step6.Error) : null;
	}
	public getErrorTrigger(): any {
		return this.Steps.Step7.ErrorTrigger ? JSON.parse(this.Steps.Step7.ErrorTrigger) : null;
	}
	public getErrorGet(): any {
		return this.Steps.Step7.ErrorGet ? JSON.parse(this.Steps.Step7.ErrorGet) : null;
	}

	/**
	 * Returns true if step 7 for the project content migration has begun and therefore the first six steps have been
	 * finished successfully. Step 7 can be running, have an error, or be already completed.
	 * @author OK
	 */
	public hasBegunContentMigration(): boolean {
		return this.Steps.Step6.State === 'complete' &&
			(this.Steps.Step7.State === 'complete' || this.Steps.Step7.State === 'error' || this.Steps.Step7.State === 'running');
	}
}
