import Vue from 'vue';
import { Module, VuexModule, Mutation, Action } from 'vuex-class-modules';
import { AuthzInterface, AuthzReq, AuthzType, Generated, ProjectAccessControl, WorkspaceAccessControl } from '@faroconnect/authz-client';
import { User } from '@/classes/authz/User';
import { AuthzUserService, AssignProjectRolesQuery, AssignProjectRolesUsersQuery } from '@/store/services/authz/AuthzUserService';
import { faroLocalization, LanguageCode } from '@faroconnect/baseui';
import { SendEmailVerificationBody, WithUserRegistrationToken, DeleteUserResponse } from '@/utils/types';
import { IAuth0Token } from '@/definitions/interfaces';
import jwtDecode from 'jwt-decode';
import { GetTokenSilentlyOptions, VueAuth } from '@faroconnect/auth0-frontend';

interface WorkspacePermissions {
	[key: string]: AuthzType.WorkspacePermissionName[];
}

interface WorkspaceAccesses {
	/**
	 * Key is the Workspace UUID.
	 * Value can be undefined because it is not provided for all possible keys.
	 */
	[key: string]: WorkspaceAccessControl | undefined;
}

interface ProjectPermissions {
	/**
	 * The first key has the UUID of the workspace. For each object it has the UUID of a project as key and the permission UUIDs as value.
	 * Value can be undefined because it is not provided for all possible keys.
	 */
	[key: string]: {
		[key: string]: AuthzType.ProjectPermissionName[] | undefined;
	} | undefined;
}

interface WorkspaceUsersMap {
	/**
	 * Key is the Workspace UUID.
	 * Value can be undefined because it is not provided for all possible keys.
	 */
	[key: string]: User[] | undefined;
}

@Module
export class UserModule extends VuexModule {
	public user: User | null = null;
	// User has email address @faro.com or @faroeurope.com.
	public isStrictFaroUser: boolean | null = null;
	// Same as `isStrictFaroUser`, but allows additional email suffixes.
	public isFaroUser: boolean | null = null;
	public users: WorkspaceUsersMap = {};
	public workspacePermissions: WorkspacePermissions = {};
	public workspaceAccesses: WorkspaceAccesses = {};
	// Add a project access control with empty project permissions to avoid having to check whether it is already instantiated.
	public projectAccessControl: ProjectAccessControl = new ProjectAccessControl({}, {
		tc: faroLocalization.i18n.tc.bind(faroLocalization.i18n),
		accessDeniedOneOfPermissionsStringId: 'UI_ACCESS_DENIED_PROJECT_PERMISSIONS',
	});
	public projectPermissions: ProjectPermissions = {};
	public auth0Token: string | null = null;
	public decodedToken: Partial<IAuth0Token> = {};

	protected readonly service = new AuthzUserService({});

	@Action
	public async initialize() {
		const response = await this.service.readAllPermissionsCurrentUser({
			workspaces: 'all',
			projects: 'all',
			user: 'full',
		});
		if (!response) {
			throw new Error('The response is undefined');
		}
		if (response.u) {
			const user = User.forRequest(response.u);
			this.setUser(user);
			await this.updateLanguage(user);
		}

		if (response.w) {
			Object.keys(response.w).forEach((uuid) => {
				if (!response.w) {
					return;
				}
				this.addWorkspacePermissions({
					workspaceUuid: uuid,
					permissionNames: response.w[uuid],
				});
				this.addWorkspaceAccesses({
					workspaceUuid: uuid,
					permissionNames: response.w[uuid],
				});
			});
		}

		if (response.p) {
			this.setAccessControl(new ProjectAccessControl(response.p, {
				tc: faroLocalization.i18n.tc.bind(faroLocalization.i18n),
				accessDeniedOneOfPermissionsStringId: 'UI_ACCESS_DENIED_PROJECT_PERMISSIONS',
			}));
			Object.keys(response.p).forEach((workspaceUuid) => {
				if (!response.p) {
					return;
				}
				Object.keys(response.p[workspaceUuid]).forEach((projectUuid) => {
					if (!response.p || !response.p[workspaceUuid]) {
						return;
					}
					this.addProjectPermissions({
						workspaceUuid,
						projectUuid,
						permissionNames: response.p[workspaceUuid][projectUuid],
					});
				});
			});
		}
	}

	@Action
	public async updateSingle(payload: { uuid: string, attributes: Partial<AuthzInterface.IUser> } ) {
		const userResponse = await this.service.updateSingle({
			...payload.attributes,
			UUID: payload.uuid,
		});
		const user = User.fromResponse(userResponse);
		this.setUser(user);
		await this.updateLanguage(user);
	}

	@Action
	public async changePasswordRequest() {
		await this.service.sendMailForChangePassword();
	}

	@Action
	public async register(user: AuthzReq.RegisterUserReq & WithUserRegistrationToken) {
		await this.service.registerUser(user);
	}

	@Action
	public async sendEmailVerification(sendEmailVerificationBody: SendEmailVerificationBody) {
		await this.service.sendEmailVerification(sendEmailVerificationBody);
	}

	@Action
	public async deleteCallingUser(): Promise<DeleteUserResponse> {
		return await this.service.deleteCallingUser();
	}

	@Action
	public async deleteCallingUserIgnoreBaseWorkspaces(): Promise<DeleteUserResponse> {
		return await this.service.deleteCallingUserIgnoreBaseWorkspaces();
	}

	@Action
	public async validateWorkspaceCreation(): Promise<{ CreateWorkspaceCheck: boolean, PendingWorkspaces: Generated.SubSvcPendingWorkspaces }> {
		return await this.service.validateWorkspaceCreation();
	}

	@Action
	public async getByEmail(query: {workspaceUuid: string, email: string, params?: any}): Promise<AuthzInterface.IUser> {
		const userResponse = await this.service.getByEmail(query.workspaceUuid, query.email, query.params);
		const user = User.fromResponse(userResponse);
		return user;
	}

	/**
	 * Gets the workspace role names of the user for the workspace info page.
	 * @author OK
	 * @param workspaceUuid Workspace UUID.
	 * @param uuid User UUID.
	 * @throws {HttpError}
	 */
	@Action
	public async getWorkspaceRoleNames({workspaceUuid, userUuid}: {workspaceUuid: string, userUuid: string}): Promise<string[]> {
		// throws HttpError
		const userResponse = await this.service.readUser(workspaceUuid, userUuid, true);
		const user = User.fromResponse(userResponse);

		const roleNamesSet: Set<string> = new Set();

		if (user.WorkspaceRoles) {
			for (const wr of user.WorkspaceRoles) {
				roleNamesSet.add(wr.Name);
			}
		}
		if (user.WorkspaceRolesFromGroups && user.WorkspaceRolesFromGroups.WorkspaceRoles) {
			for (const wr of user.WorkspaceRolesFromGroups.WorkspaceRoles) {
				roleNamesSet.add(wr.Name);
			}
		}

		const roleNames = Array.from(roleNamesSet);
		roleNames.sort();
		return roleNames;
	}

	/**
	 * Calls AuthZ to init the process to change the user's email address.
	 * @author OK
	 * @param newEmail The new email address of the user.
	 * @throws {HttpError}
	 */
	@Action
	public async initEmailChange(newEmail: string): Promise<void> {
		// throws HttpError
		return await this.service.initEmailChange(newEmail);
	}

	/**
	 * Calls AuthZ to finish the process to change the user's email address.
	 * @author OK
	 * @param tokenUuid UUID of the email token in AuthZ's database.
	 * @returns The user object with the updated email attribute.
	 * @throws {HttpError}
	 */
	@Action
	public async finishEmailChange(tokenUuid: string): Promise<User> {
		// throws HttpError
		const tokenUser = await this.service.finishEmailChange(tokenUuid);
		// Since the tokenUser could theoretically be a different user than the one logged in, and since we don't need it,
		// we don't call this.setUser(tokenUser) any more.
		// // The State attribute is missing in AuthZ's response. So we use the current value.
		// // Since we don't require login for the ChangeEmailPage, this.user is most likely undefined.
		// tokenUser.State = this.user?.State || 'active';
		// this.setUser(tokenUser);
		return tokenUser;
	}

	public async getWorkspacePermissions(workspaceUuid: string) {
		const permissions = await this.service.readPermissionsCurrentUser(workspaceUuid, {
			workspaces: true,
		});
		const permissionNames = permissions?.w?.[workspaceUuid];
		if (!permissionNames) {
			throw new Error('Missing workspace permissions');
		}
		// Replace the permissions since they might've changed.
		this.addWorkspacePermissions({
			workspaceUuid,
			permissionNames,
		});
		// Replace the permissions since they might've changed.
		this.addWorkspaceAccesses({
			workspaceUuid,
			permissionNames,
		});
	}

	@Mutation
	public setUser(user: User) {
		this.user = user;
		const email = (user.Email || '').toLowerCase();
		this.isStrictFaroUser = email.endsWith('@faro.com') || email.endsWith('@faroeurope.com');
		// This parameter is used to check if the user is a FARO employee. e.g. we need it to enable a feature for all Faro users (@faro.com & co).
		this.isFaroUser = this.isStrictFaroUser || email.endsWith('@holobuilder.com') || email.endsWith('@builditsoftware.com');
	}

	@Mutation
	public setAccessControl(projectAccessControl: ProjectAccessControl) {
		this.projectAccessControl = projectAccessControl;
	}

	@Mutation
	public addWorkspacePermissions(payload: { workspaceUuid: string, permissionNames: AuthzType.WorkspacePermissionName[] }) {
		Vue.set(this.workspacePermissions, payload.workspaceUuid, payload.permissionNames);
	}

	@Mutation
	public addWorkspaceAccesses(payload: { workspaceUuid: string, permissionNames: AuthzType.WorkspacePermissionName[] }) {
		const workspaceAccess = new WorkspaceAccessControl(
			payload.permissionNames,
			{
				tc: faroLocalization.i18n.tc.bind(faroLocalization.i18n),
				accessDeniedOneOfPermissionsStringId: 'UI_ACCESS_DENIED_WORKSPACE_PERMISSIONS',
			},
			payload.workspaceUuid,
		);
		Vue.set(this.workspaceAccesses, payload.workspaceUuid, workspaceAccess);
	}

	@Mutation
	public addProjectPermissions(payload: { workspaceUuid: string, projectUuid: string, permissionNames: AuthzType.ProjectPermissionName[] }) {
		const projectPermissions = this.projectPermissions[payload.workspaceUuid];
		if (projectPermissions) {
			Vue.set(projectPermissions, payload.projectUuid, payload.permissionNames);
		} else {
			Vue.set(this.projectPermissions, payload.workspaceUuid, {
				[payload.projectUuid]: payload.permissionNames,
			});
		}
	}

	public getWorkspaceAccessControl(workspaceUuid: string): WorkspaceAccessControl {
		return this.workspaceAccesses[workspaceUuid] ?? new WorkspaceAccessControl([], {
			tc: faroLocalization.i18n.tc.bind(faroLocalization.i18n),
			accessDeniedOneOfPermissionsStringId: 'UI_ACCESS_DENIED_WORKSPACE_PERMISSIONS',
		}, workspaceUuid);
	}

	@Action
	public async getAllFromWorkspace(payload: { workspaceUuid: string, query: any }) {
		const iUsers = await this.service.getAllFromWorkspace(payload.workspaceUuid, payload.query);
		let users = iUsers.map((user) => User.fromResponse(user));

		// Like in AuthZ's frontend, remove all QA users so that the customer doesn't see them.
		users = users.filter((user) => !user.QA);

		this.replaceUsers({ workspaceUuid: payload.workspaceUuid, users });
		return users;
	}

	@Action
	public async getUser(payload: { workspaceUuid: string, uuid: string }) {
		const workspaceUsers: User[] = this.users[payload.workspaceUuid] ?? [];
		let user = workspaceUsers.find((u) => u.UUID === payload.uuid);
		if (!user) {
			const iUser = await this.service.readUser(payload.workspaceUuid, payload.uuid);
			user = User.fromResponse(iUser);
			this.setWorkspaceUser({ workspaceUuid: payload.workspaceUuid, user });
		}
		return user;
	}

	@Action
	public async getUserWithWorkspaceRoles(payload: { workspaceUuid: string, uuid: string }) {
		const iUser = await this.service.readUser(payload.workspaceUuid, payload.uuid, true);
		return User.fromResponse(iUser);
	}

	@Action
	public async readCallingUser(query?: { compliancecheck: boolean }) {
		const userJson = await this.service.readCallingUser(query);
		const user = User.fromResponse(userJson);
		this.setUser(user);
		return user;
	}

	@Action
	public async getTokenSilently(options?: GetTokenSilentlyOptions | undefined): Promise<undefined | string> {
		const vue: Vue = Vue.prototype;
		// The token is already cached by Auth0, do not cache it here
		// because it could lead to an expired token.
		const auth0Token = await vue.$auth.getTokenSilently(options);
		if (auth0Token) {
			this.setAuth0Token(auth0Token);
		}
		return auth0Token;
	}

	@Action
	public async readTokenInfo(): Promise<Generated.TokenInfo> {
		return await this.service.readTokenInfo();
	}

	@Mutation
	public setWorkspaceUser(payload: { workspaceUuid: string, user: User }) {
		const workspaceUsers: User[] = this.users[payload.workspaceUuid] ?? [];
		const found = workspaceUsers.findIndex((user) => user.UUID === payload.user.UUID);
		const index = found > -1 ? found : workspaceUsers.length;
		const count = found > -1 ? 1 : 0;
		workspaceUsers.splice(index, count, payload.user);
		Vue.set(this.users, payload.workspaceUuid, workspaceUsers);
	}

	@Mutation
	public replaceUsers(payload: { workspaceUuid: string, users: User[] }) {
		Vue.set(this.users, payload.workspaceUuid, payload.users);
	}

	@Mutation
	public setAuth0Token(auth0Token: string) {
		if (auth0Token !== this.auth0Token) {
			this.decodedToken = jwtDecode(auth0Token);
		}
		this.auth0Token = auth0Token;
	}

	public async assignProjectRoles(workspaceUuid: string, userUuid: string, query: AssignProjectRolesQuery) {
		await this.service.assignProjectRoles(workspaceUuid, userUuid, query);
	}

	public async assignProjectRolesUsers(workspaceUuid: string, query: AssignProjectRolesUsersQuery) {
		await this.service.assignProjectRolesUsers(workspaceUuid, query);
	}

	public async isUserRegisteredWithSSO() {
		const userInfo = await(Vue.prototype.$auth as VueAuth).getIdTokenClaims();
		const auth0UserId = userInfo?.sub || '';
		return !auth0UserId.startsWith('auth0|');
	}

	/**
	 * Sets the language to be used. If the language was registered as asynchronous, it will first load it.
	 * @author OK
	 * @param user User whose Language attribute is used.
	 */
	public async updateLanguage(user: User): Promise<void> {
		await faroLocalization.setLanguage(user.Language as LanguageCode);
	}
}
