import { AuthzType } from '@faroconnect/authz-client';
import { countryNames, faroLocalization } from '@faroconnect/baseui';
import { InternationalizationExtendedUtils, InternationalizationUtils } from '@faroconnect/utils';
import { DataTableHeader } from 'vuetify';

export type StateOrProvince =
	InternationalizationUtils.UsState |
	InternationalizationExtendedUtils.CanadaState |
	InternationalizationExtendedUtils.AustraliaState |
	InternationalizationExtendedUtils.NewZealandState |
	InternationalizationExtendedUtils.IndiaState |
	InternationalizationExtendedUtils.ChinaState |
	InternationalizationExtendedUtils.JapanState |
	InternationalizationExtendedUtils.SouthKoreaState |
	InternationalizationExtendedUtils.ThailandState |
	InternationalizationExtendedUtils.MalaysiaState |
	InternationalizationExtendedUtils.IndonesiaState |
	InternationalizationExtendedUtils.VietnamState;

export function phoneCodes(): DataTableHeader[] {
	return Object.keys(InternationalizationExtendedUtils.countryPhoneCodesFromCountryCode)
		.map((countryCode) => ({
			text: faroLocalization.i18n.tc(countryNames[countryCode as keyof typeof countryNames]) + ' +' +
				InternationalizationExtendedUtils.countryPhoneCodesFromCountryCode[countryCode as keyof typeof countryNames],
			value: countryCode,
		}))
		.sort((a, b) => a.text.localeCompare(b.text));
}

export function stateOrProvinces(country: InternationalizationUtils.CountryCode | ''): DataTableHeader[] {
	let states: { [key: string]: string };
	switch (country) {
		case 'US':
			states = InternationalizationUtils.usStateNames;
			break;
		case 'CA':
			states = InternationalizationExtendedUtils.canadaStateNames;
			break;
		case 'AU':
			states = InternationalizationExtendedUtils.australiaStateNames;
			break;
		case 'NZ':
			states = InternationalizationExtendedUtils.newZealandStateNames;
			break;
		case 'IN':
			states = InternationalizationExtendedUtils.indiaStateNames;
			break;
		case 'CN':
			states = InternationalizationExtendedUtils.chinaStateNames;
			break;
		case 'JP':
			states = InternationalizationExtendedUtils.japanStateNames;
			break;
		case 'KR':
			states = InternationalizationExtendedUtils.southKoreaStateNames;
			break;
		case 'TH':
			states = InternationalizationExtendedUtils.thailandStateNames;
			break;
		case 'MY':
			states = InternationalizationExtendedUtils.malaysiaStateNames;
			break;
		case 'ID':
			states = InternationalizationExtendedUtils.indonesiaStateNames;
			break;
		case 'VN':
			states = InternationalizationExtendedUtils.vietnamStateNames;
			break;
		default:
			return [];
	}

	return Object.keys(states)
		.map((key) => ({
			text: states[key],
			value: key,
		}))
		.sort((a, b) => a.text.localeCompare(b.text));
}

export function requiresStateOrProvince(country: InternationalizationUtils.CountryCode | ''): boolean {
	switch (country) {
		case 'US':
		case 'CA':
		case 'AU':
		case 'NZ':
		case 'IN':
		case 'CN':
		case 'JP':
		case 'KR':
		case 'TH':
		case 'MY':
		case 'ID':
		case 'VN':
			return true;
		default:
			return false;
	}
}

export function translateIndustry(industry: AuthzType.Industry): string {
	switch (industry) {
		case 'AEC':
			return faroLocalization.i18n.tc('LP_AEC');
		case 'MANUFACTURING':
			return faroLocalization.i18n.tc('LP_MANUFACTURING');
		case 'PS':
			return faroLocalization.i18n.tc('LP_PS');
		default:
			return '';
	}
}

export function translatePrimaryApplicationInterest(primaryApplicationInterest: AuthzType.ApplicationInterest): string {
	switch (primaryApplicationInterest) {
		case 'QualityControlInspection':
			return faroLocalization.i18n.tc('LP_INTEREST_QCI');
		case 'ProductionFabricationAssembly':
			return faroLocalization.i18n.tc('LP_INTEREST_PFA');
		case 'ProductDesignEngineering':
			return faroLocalization.i18n.tc('LP_INTEREST_PDE');
		case 'Other':
			return faroLocalization.i18n.tc('UI_OTHER');
		case 'AsBuiltCaptureModeling':
			return faroLocalization.i18n.tc('LP_INTEREST_ACM');
		case 'ConstructionPrefabrication':
			return faroLocalization.i18n.tc('LP_INTEREST_CP');
		case 'ConstructionQualityControl':
			return faroLocalization.i18n.tc('LP_INTEREST_CQC');
		case 'DesignLayoutProjection':
			return faroLocalization.i18n.tc('LP_INTEREST_DLP');
		case 'DigitalTwinFacilityManagement':
			return faroLocalization.i18n.tc('LP_INTEREST_DT');
		case 'HistoricPreservation':
			return faroLocalization.i18n.tc('LP_INTEREST_HP');
		case 'ForensicAnalysisPreIncidentPlanning':
			return faroLocalization.i18n.tc('LP_INTEREST_FPP');
		default:
			return '';
	}
}

/**
 * Removes leading, trailing and extra "in-between words" whitespaces from a string.
 * @param str String.
 * @returns New cleaned up string.
 */
export function removeUnnecessarySpaces(str: string): string {
	return str ? str.replace(/\s+/g, ' ').trim() : '';
}
